@import '@fontsource-variable/public-sans';

@font-face {
  font-family: 'dengxian';
  src: url('../src/fonts/FZXiDengXian-Z06S.ttf');
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Roboto, sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto';
}

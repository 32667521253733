@import 'draft-js/dist/Draft.css';
@import '@draft-js-plugins/alignment/lib/plugin.css';
@import '@draft-js-plugins/mention/lib/plugin.css';

.editor {
  box-sizing: border-box;
  cursor: text;
  padding: 10px 16px;
  border-radius: 2px;
}

.editorOutline {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.editor :global(.public-DraftEditor-content) {
  min-height: '100%';
  min-width: 250px;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
  line-height: 1.4;
  margin: 1rem;
}
table {
  border-collapse: collapse;
}
textarea figure {
  display: table;
  margin: 1rem auto;
}
textarea figure figcaption {
  color: #999;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
}
hr {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 0 0;
}
code {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.1rem 0.2rem;
}
.mce-content-body:not([dir='rtl']) blockquote {
  border-left: 2px solid #ccc;
  margin-left: 1.5rem;
  padding-left: 1rem;
}
.mce-content-body[dir='rtl'] blockquote {
  border-right: 2px solid #ccc;
  margin-right: 1.5rem;
  padding-right: 1rem;
}

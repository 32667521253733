.page-grid .react-grid-item {
  transition: all 50ms ease;
  transition-property: left, top;
}

.page-grid .react-grid-item.cssTransforms {
  transition-property: transform;
}

.page-grid .react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.page-grid .react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.page-grid .react-grid-item.react-grid-placeholder {
  background: #81d4fa;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  user-select: none;
  border-radius: 0px;
}

.page-grid .react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

:root {
  --primary-font-size: 14pt;
  --primary-font-family: 'Roboto', sans-serif;
  --p-size: 14pt;
  --base-size: 10pt;
  --h1-size: calc(var(--base-size) + 6pt);
  --h2-size: calc(var(--base-size) + 5pt);
  --h3-size: calc(var(--base-size) + 4pt);
  --h4-size: calc(var(--base-size) + 3pt);
  --h5-size: calc(var(--base-size) + 2pt);
  --h6-size: calc(var(--base-size) + 2pt);
  --title-size: calc(var(--h1-size) + 7pt);
  --primary-color: #2196f3;
}

.radius {
  border-radius: 10px;
}

@font-face {
  font-family: 'dengxian';
  src: url('fonts/FZXiDengXian-Z06S.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'signature';
  src: url('fonts/PrettyGirlsScriptDemo.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'signature-extended';
  src: url('fonts/signature.ttf');
  font-style: normal;
  font-weight: normal;
}

/*
  Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license
*/
@font-face {
  font-family: 'FabricMDL2Icons';
  src: url('fonts/fabric-icons-2ab7a968.woff') format('woff');
}

.ms-Icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'FabricMDL2Icons';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
}

.ms-Icon-mini {
  font-size: 16px;
}

.ms-Icon--ExcelLogoInverse16:before {
  content: '\F396';
}

.ms-Icon--FileImage:before {
  content: '\F311';
}

.ms-Icon--MusicInCollectionFill:before {
  content: '\EA36';
}

.ms-Icon--PDF:before {
  content: '\EA90';
}

.ms-Icon--PhotoVideoMedia:before {
  content: '\F0B1';
}

.ms-Icon--PowerPointLogoInverse16:before {
  content: '\F393';
}

.ms-Icon--WordLogoInverse16:before {
  content: '\F390';
}

.blue {
  color: #3a66a7;
}

.center {
  text-align: center;
}

/* .dengxian {
  font-family: dengxian;
  font
} */

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.no-m-p {
  margin: 0px;
  padding: 0px;
}

.search-border {
  /* border: 2px solid #bebebe; */
  border-radius: 10px;
  padding: 10px;
  margin: 10px auto;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
  color: #404040;
}

.account-pop {
  cursor: pointer;
  padding: 10px;
  margin: 0;
  width: 120px;
}

.account-noti {
  cursor: pointer;
  padding: 10px;
  margin: 0;
  justify-content: space-between;
  display: 'flex';
  width: 200px;
}

.account-pop:hover {
  background-color: #ebebeb;
}

.input-transparent {
  height: 40px;
  width: 40px;
  outline: none;
  cursor: pointer;
  opacity: 0;
}

.package {
  padding: 20px;

  border-radius: 10px;
  margin: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  text-align: center;
  height: 120px;
  color: #909090;
}

.package-selected {
  padding: 35px;
  color: #3a66a7;

  border-radius: 10px;
  margin: 20px;
  margin-top: 5px;
  height: 150px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.progressbar-progress {
  height: 30px !important;
  background-color: lightgreen !important;
  padding: 5px;
  border-radius: 5px;
}

.zoom {
  transition: transform 0.5s;
  /* Animation */
}

.zoom:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .mobile-nomargin {
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  .mobile-row {
    flex-direction: column;
  }

  .mobile-spacing {
    justify-content: space-between;
  }

  .mobile-nomargin {
    margin: 0;
  }
}

/* width */
.scroll-bar ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll-bar ::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
.scroll-bar ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
.scroll-bar ::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 3px;
}

.light-scroll-bar ::-webkit-scrollbar {
  width: 14px;
  height: 16px;
}

.light-scroll-bar ::-webkit-scrollbar-track {
  background: inherit;
}

.light-scroll-bar ::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 8px;
  opacity: 0.5;
}

.light-scroll-bar ::-webkit-scrollbar-thumb:hover {
  background: #757575;
  border-radius: 8px;
  opacity: 0.5;
}

.horizontal-scroll-bar ::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

.horizontal-scroll-bar ::-webkit-scrollbar-track {
  background: inherit;
}

.horizontal-scroll-bar ::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 6px;
}

.horizontal-scroll-bar ::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
  border-radius: 6px;
}

.react-flow__handle {
  width: 16px !important;
  height: 16px !important;
}

.react-flow__handle-bottom {
  bottom: -8px !important;
}

.react-flow__handle-top {
  top: -8px !important;
}

.react-flow__node-default .react-flow__handle {
  background-color: #002171 !important;
}

.in-line-div {
  display: inline-block;
  margin: 0% 0 0% 0;
  width: 800px;
}

.in-line-container {
  text-align: center;
}

.sized-container-div {
  margin: auto;
  height: 100vh;
  width: 600px;
}

.full-window-div {
  height: 80vh;
  border: 1px solid #dadada;
}

@media screen and (max-width: 1440px) {
  .full-window-div {
    height: 80vh;
  }
}

.full-screen-div {
  height: 100vh;
  border: 1px solid #dadada;
}

.light-box-container {
  background-color: #fdfdf9;
  height: 100%;
}

.lb-view-file-btn {
  background-color: #1473e6;
  border: 1px #404040;
  border-radius: 5px;
  color: #ededed;
  cursor: pointer;
  font-family: 'Arial';
  margin: 100px 0 0 80px;
  padding: 10px;
  width: 120px;
}

.lb-view-file-btn:hover {
  background-color: #0969df;
  color: white;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #2196f3;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  user-select: none;
  border-radius: 5px;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.player-wrapper {
  height: 65vh;
}

.react-player {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
}

.highlighted-element {
  animation: highlight 8000ms ease-out;
}

.shortly-highlighted-element {
  animation: highlight 1200ms ease-out;
}

@keyframes highlight {
  from {
    background-color: #81d4fa;
  }
}

.highlighted-green-element {
  animation: greenhighlight 5000ms ease-out;
}

@keyframes greenhighlight {
  from {
    background-color: #66bb6a;
  }
}

.error-highlight {
  animation: errorhighlight 5000ms ease-out;
}

@keyframes errorhighlight {
  from {
    background-color: #f44336;
  }
}

div#adobe-dc-view {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 9999;
}

/* tiff viewer css */

._1Lxpd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}

._35l6Q {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._35l6Q ._1oXyH {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

canvas {
  max-width: 1024px;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  max-height: 700px;
  -o-object-fit: contain;
  object-fit: contain;
}

canvas:hover {
  cursor: pointer;
}

._2hTXI {
  padding: 0.4rem 2rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #141414;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

._2hTXI:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

._2yRPI {
  margin: 0 16px;
  font-size: 1rem;
  font-weight: 500;
  color: #aaa;
  width: 100px;
  display: inline-block;
  text-align: center;
}

._Tn-iv button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #141414;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  z-index: 99;
  padding: 5px 12px;
}

._Tn-iv button:nth-child(1) {
  left: 5%;
}

._Tn-iv button:nth-child(2) {
  right: 5%;
}

._2hsM7 {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 99;
  border: none;
  color: #141414;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 12px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

._2hsM7:hover {
  color: #fff;
}

._2hsM7 svg {
  width: 20px;
  height: 20px;
}

.invalid-row {
  color: #f44336;
  font-weight: bold;
}

.doc-link {
  color: #2196f3;
  cursor: pointer;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f0f0f0;
}

.doc-link:hover {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}
